import { Form, Input } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { ICounselingFormBaseProps } from "../interface";
import VuiFormTitle from "../../../@vodea/vodea-ui/components/Form/Title";

const CounselingFormShortAnswer: React.FC<ICounselingFormBaseProps> = ({
  question,
  disabled,
  forPrint,
}) => {
  const { t } = useTranslation();
  const formName = `${question.id}-${question.type}`;
  return forPrint ? (
    <Form.Item
      rules={[
        {
          required: Boolean(question.is_required),
          message: t("validation.required", {
            item: "Answer",
          }),
        },
      ]}
      shouldUpdate={(prevValues, curValues) =>
        prevValues[formName] !== curValues[formName]
      }
      tooltip={question.tooltip}
    >
      {({ getFieldValue }) => (
        <div>
          <VuiFormTitle
            title={question.question}
            required={Boolean(question.is_required)}
          />

          <div
            className="mt12"
            dangerouslySetInnerHTML={{ __html: getFieldValue(formName) }}
          />
        </div>
      )}
    </Form.Item>
  ) : (
    <Form.Item
      name={`${question.id}-${question.type}`}
      label={question.question}
      rules={[
        {
          required: Boolean(question.is_required),
          message: t("validation.required", {
            item: "Answer",
          }),
        },
      ]}
      tooltip={question.tooltip}
    >
      <Input
        disabled={disabled}
        size={"large"}
        placeholder={t("common.text.input", { item: "Answer" })}
      />
    </Form.Item>
  );
};

export default CounselingFormShortAnswer;
